.scrollarrow {
  text-align: center;
  text-decoration: none !important;
 
  width: 50px;
  height: 50px;
  border-radius: 50%;
 
//  color: black;
//  background-color: grey;
 
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: none;

  &:hover {
    color: black;
  }

 }
 @media (min-width: 40rem) {
  .scrollarrow {
      right: 25px;
      bottom: 60px;
  }
 }
 .scrollarrow div {
  font-weight: bold;
 }

